@use "../../sass/global/" as *;
@use "../../sass/base/" as *;

.header {
  color: white; // ヘッダー内の文字色
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  font-family: CircularStd;
  @include responsive(tab) {
    display: none;
  }
}

.rights {
  display: flex;
  align-items: center;
}

.header_logo {
  font-size: 24px; // ロゴのフォントサイズ
  font-weight: bold;
  img {
    height: 20px;
    -webkit-animation: slideInFromRight 0.6s both;
    animation: slideInFromRight 0.6s both;
    animation-delay: 2s;
  }
}

.headerTitle {
  display: flex;
  gap: 24px; // タイトル間の間隔
  margin-right: 16px;
  span {
    cursor: pointer;
    font-family: CircularStd;
    font-size: 16px;
    -webkit-animation: slideInFromRight 0.6s both;
    animation: slideInFromRight 0.6s both;
    animation-delay: 2s;
    &:hover {
      text-shadow: 2px 2px 8px white;
    }
  }
}

.header_downloadButton {
  display: flex;
  gap: 10px; // ボタン間の間隔
  cursor: pointer;
  -webkit-animation: slideInFromRight 0.6s both;
  animation: slideInFromRight 0.6s both;
  animation-delay: 2s;
}

.header_downloadButton_apple {
  background-color: rgba(255, 255, 255, 0.5); // ボタンの背景色
  color: white; // ボタンの文字色
  padding: 8px 12px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px; // アイコンとテキスト間の間隔
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: rgba(240, 240, 240, 0.7);
    /* Add text-shadow transition on hover */
    box-shadow: 0 0 24px white;
  }
  img {
    width: 22px;
    height: 22px;
  }
}
