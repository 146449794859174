@use "../../sass/global/" as *;
@use "../../sass/base/" as *;

/* styles.module.scss */
.container {
  position: fixed;
  /* コンテナを相対位置に設定 */
  // overflow: hidden; /* はみ出した部分を隠す */
  overflow-x: hidden;
  display: none;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: black;
  transform: translateX(100%);
  padding-top: 40px;
  box-sizing: border-box;

  @include responsive(tab) {
    display: flex;
  }
}

.titles {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;

  h2 {
    @include fz_vw(56);
    line-height: 1.2;
    color: white;
    text-shadow: 4px 4px 16px rgba(255, 255, 255, 0.7);
    -webkit-animation: bounceInRight 1.1s both;
    animation: bounceInRight 1.1s both;
    transform-origin: left center;
    font-weight: bold;

    @include responsive(tab) {
      @include fz_vw(32);
    }
  }
}

.buttons {
  display: flex; // 横並びにするためにflexboxを使用
  gap: 12px; // ボタン間のスペース
  @include mt_vw(32);
  -webkit-animation: slideInFromRight 0.6s both;
  animation: slideInFromRight 0.6s both;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @include responsive(tab) {
    @include mt_vw(24);
  }

  // ボタン要素の定義
  &__button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 12px 16px;
    background-color: #ffffffa4;
    border-radius: 15px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Add transition for background-color and text-shadow */

    &:hover {
      background-color: rgba(240, 240, 240, 0.7);
      /* Add text-shadow transition on hover */
      box-shadow: 0 0 24px white;
    }

    img {
      width: 20px;
      height: 20px;

      &:hover {
        text-shadow: 0 0 8px white;
      }
    }

    p {
      margin: 0;
      color: white;
      font-size: 16px;
    }
  }
}

.pagesSegues {
  display: flex;
  flex-wrap: wrap;
  @include mt_vw(40);
  width: 100%;
  justify-content: center;

  @include responsive(tab) {
    flex-direction: column;
    @include mt_vw(24);
  }

  &__button {
    height: 12vw;
    width: 40vw;
    max-width: 600px;
    // max-height: 200px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    border: 1px solid rgb(42, 42, 42);
    transition: height 0.5s;
    margin: 12px;
    cursor: pointer;

    @include responsive(tab) {
      height: 20vw;
      width: 80vw;
      max-width: 600px;
      max-height: 200px;
    }

    @include responsive(sp) {
      height: 30vw;
      width: 90vw;
      max-width: 600px;
      max-height: 200px;
    }

    h2 {
      top: 0px;
      @include fz_vw(24);
      margin-left: 24px;
      margin-top: 32px;
      position: absolute;
      z-index: 1000;

      @include responsive(tab) {
        @include fz_vw(16);
      }

      @include responsive(sp) {
        @include fz_vw(24);
        margin-top: 16px;
      }
    }

    p {
      margin-left: 24px;
      margin-top: 12px;
      font-size: 16px;
      color: white;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }
}

.header__bg {
  width: 100vw;
  height: 100vh;
  left: 0;
  position: fixed;
  animation: 3s scaleUpBg forwards;
  transform: scale(1);
  pointer-events: none;
}

.rotateAndBounceOut {
  animation: rotateAndBounceOut 2s forwards;
}

.slideLeftFadeOut {
  animation: slideLeftFadeOut 0.5s forwards;
  /* 2秒間のアニメーション */
}

//==================================================hamburgerbutton==========================================
$duration: 0.35s;

.menu {
  width: 30px;
  height: 20px;
  margin: 0 auto;
  position: fixed;
  z-index: 10000;
  right: 16px;
  top: 16px;
  display: none;

  @include responsive(tab) {
    display: inline;
  }

  @include responsive(sp) {
    display: none;
  }
}

.openHambergerMenu {
  animation: openHambergerMenu 0.3s forwards;
}

.closeHambergerMenu {
  animation: openHambergerMenu 0.3s forwards reverse;
}