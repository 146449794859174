@keyframes slideInFromRight {
  0% {
    transform: translateX(15%); /* 右からスタート */
    opacity: 0;
  }
  100% {
    transform: translateX(0%); /* 最終的な位置 */
    opacity: 1;
  }
}
