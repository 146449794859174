@use "../../sass/global/" as *;
@use "../../sass/base/" as *;

/* styles.module.scss */
.container {
  position: relative;
  /* コンテナを相対位置に設定 */
  height: 200vh;
  /* ビューポートの高さに合わせる */
  overflow: hidden;
  /* はみ出した部分を隠す */
  background-color: black;

  @include responsive(sp) {
    height: 100vh;

  }
}

.titles {
  position: fixed;
  top: 26vh;
  left: 10vw;

  @include responsive(tab) {
    top: 6vh;
  }

  @include responsive(sp) {
    position: relative;
  }

  h2 {
    @include fz_vw(56);
    line-height: 1.2;
    color: white;
    text-shadow: 4px 4px 16px rgba(255, 255, 255, 0.7);
    -webkit-animation: bounceInRight 1.1s both;
    animation: bounceInRight 1.1s both;
    animation-delay: 2s;
    transform-origin: left center;
    font-weight: bold;

    @include responsive(tab) {
      @include fz_vw(40);
    }

    @include responsive(sp) {
      @include fz_vw(32);
    }
  }

  .subtitle {
    @include mt_vw(40);
    font-size: 16px;
    line-height: 2;
    font-weight: bold;
    color: white;
    width: 30vw;
    line-height: 1.5;
    text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.7);
    -webkit-animation: slideInFromRight 0.6s both;
    animation: slideInFromRight 0.6s both;
    animation-delay: 2.2s;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;

    @include responsive(tab) {
      width: 82vw;
      // margin-top: 4vh;
      @include mt_vw(32);
    }
  }
}

.firstVideo {
  position: fixed;
  /* 動画を固定位置に設定 */
  top: 0%;
  /* 上端に合わせる */
  right: 0px;
  /* 左端に合わせる */
  width: 100%;
  /* 幅を画面いっぱいに広げる */
  height: 100%;
  /* 高さを画面いっぱいに広げる */
  object-fit: contain;
  opacity: 0;
  transform: rotate(45deg) scale(0.2) translateX(0);
  /* 初期状態は縮小して、位置は変わらず */
  animation: scaleUp 3s forwards;

  /* 5秒間で無限にスケールアップ */
  @include responsive(tab) {
    animation: scaleUpTab 3s forwards;
    /* 5秒間で無限にスケールアップ */
  }

  @include responsive(sp) {
    animation: scaleUpSp 3s forwards;
    position: absolute;
    transform: inherits;
    /* 5秒間で無限にスケールアップ */
  }
}

.nextPageButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  @include mt_vw(32);
  cursor: pointer;
  -webkit-animation: slideInFromRight 0.6s both;
  animation: slideInFromRight 0.6s both;
  animation-delay: 2.4s;

  img {
    height: 22px;
    width: 22px;
    margin-top: 6px;
    transform: scale(1) translateX(0px);
    transition: transform 0.5s;
  }

  &:hover>img {
    transform: scale(1) translateX(4px);
    box-shadow: 0 0 8px white;
    border-radius: 32px;
  }

  .linearWipe {
    text-align: center;
    width: auto !important;
    text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.7);
    line-height: 1.5;
    background: linear-gradient(to right,
        #d1bcde 20%,
        #a8dae7 40%,
        #e2e3b9 60%,
        #92e8b4 80%);
    background-size: 200% auto;
    color: #000;
    font-size: 24px;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin: 0;
    margin-left: 8px;
    font-family: CircularStd;
    display: inline-block;
    animation: shine 2s linear infinite;

    @keyframes shine {
      to {
        background-position: 200% center;
      }
    }
  }
}

.buttons {
  display: flex; // 横並びにするためにflexboxを使用
  gap: 12px; // ボタン間のスペース
  @include mt_vw(16);
  -webkit-animation: slideInFromRight 0.6s both;
  animation: slideInFromRight 0.6s both;
  animation-delay: 2.1s;
  flex-wrap: wrap;

  // ボタン要素の定義
  &__button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 12px 16px;
    background-color: #ffffffa4;
    border-radius: 15px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
      background-color: rgba(240, 240, 240, 0.7);
      /* Add text-shadow transition on hover */
      box-shadow: 0 0 24px white;
    }

    img {
      width: 20px;
      height: 20px;

      &:hover {
        text-shadow: 0 0 8px white;
      }
    }

    p {
      margin: 0;
      color: white;
      font-size: 16px;
    }
  }
}

.header__bg {
  width: 100vw;
  height: 100vh;
  left: 0;
  position: fixed;
  animation: 3s scaleUpBg forwards;
  transform: scale(1);
  pointer-events: none;
}

.rotateAndBounceOut {
  animation: rotateAndBounceOut 2s forwards;
}

.slideLeftFadeOut {
  animation: slideLeftFadeOut 0.5s forwards;
  /* 2秒間のアニメーション */
}