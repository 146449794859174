@keyframes rotateAndBounceOut {
  0% {
    transform: rotate(360deg);
  }
  10% {
    transform: translateX(0%) rotate(365deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(100%) rotate(0deg);
    opacity: 0;
  }
}
