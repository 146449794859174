@keyframes shake {
  0% {
    transform: rotate(360deg);
  }
  20% {
    transform: rotate(355deg);
  }
  40% {
    transform: rotate(360deg);
  }
  60% {
    transform: rotate(355deg);
  }
  80% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
