@use "../../sass/global/" as *;
@use "../../sass/base/" as *;

/* styles.module.scss */
.container {
  position: relative;
  /* コンテナを相対位置に設定 */
  height: 200vh;
  /* ビューポートの高さに合わせる */
  overflow: hidden;

  /* はみ出した部分を隠す */
  @include responsive(sp) {
    height: 100vh;
  }
}

.titles {
  position: fixed;
  top: 14vh;
  left: 10vw;

  @include responsive(tab) {
    top: 6vh;
  }

  @include responsive(sp) {
    top: 9vh;
    left: 7vw;
    position: absolute;
  }

  h2 {
    @include fz_vw(48);
    line-height: 1.3;
    font-weight: bold;
    color: white;
    text-shadow: 4px 4px 16px rgba(255, 255, 255, 0.7);
    -webkit-animation: bounceInRight 1.1s both;
    animation: bounceInRight 1.1s both;
    transform-origin: left center;
    // font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-family: CircularStd;

    @include responsive(tab) {
      @include fz_vw(24);
    }

    @include responsive(sp) {
      @include fz_vw(32);
    }
  }

  .subtitle {
    @include mt_vw(32);
    font-size: 16px;
    line-height: 2;
    font-weight: bold;
    color: white;
    width: 30vw;
    line-height: 1.5;
    text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.7);
    -webkit-animation: slideInFromRight 0.6s both;
    animation: slideInFromRight 0.6s both;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;

    @include responsive(tab) {
      width: 82vw;
      @include mt_vw(16);
    }
  }
}

.titlesBottom {
  top: auto;
  bottom: 14vh !important;
}

.titlesSecond {
  left: auto;
  right: 10vw !important;

  @include responsive(sp) {
    left: 10vw !important;
  }
}

.firstVideo {
  position: fixed;
  /* 動画を固定位置に設定 */
  top: 0;
  /* 上端に合わせる */
  left: 0;
  /* 左端に合わせる */
  width: 100%;
  /* 幅を画面いっぱいに広げる */
  height: 100%;
  /* 高さを画面いっぱいに広げる */
  object-fit: cover;
  display: inline;

  @include responsive(sp) {
    display: none;
    position: absolute;
  }
}

.firstImg {
  position: fixed;
  /* 動画を固定位置に設定 */
  top: 0;
  /* 上端に合わせる */
  left: 0;
  /* 左端に合わせる */
  width: 100%;
  /* 幅を画面いっぱいに広げる */
  height: 100%;
  /* 高さを画面いっぱいに広げる */
  object-fit: cover;
  display: none;

  @include responsive(sp) {
    display: inline;
    position: absolute;
  }
}

.firstPhone {
  position: fixed;
  z-index: 100;
  object-fit: contain;
  right: -20%;
  bottom: 0px;
  height: 85vh;
  width: 60vw;
  transform-origin: right bottom;
  /* 回転の基点を右下に設定 */
  transform: translateX(100%) rotate(0deg);
  // animation: rotateAndBounceIn 1.5s ease-in-out forwards;

  // &:hover {
  //   animation: shake 1s ease-in-out infinite,
  //     rotateAndBounceIn 1.5s ease-in-out forwards;
  // }
  @include responsive(tab) {
    width: 100vw;
    right: -20%;
    bottom: -15vh;
  }

  @include responsive(sp) {
    height: 812px;
    width: 400px;
    right: -30%;
    bottom: -250px;
    position: absolute;
  }
}

.secondPhone {
  right: auto;
  left: -20%;
  transform-origin: left bottom;
  /* 回転の基点を右下に設定 */

  @include responsive(tab) {
    width: 100vw;
    left: -20%;
    bottom: -15vh;
  }

  @include responsive(sp) {
    height: 812px;
    width: 400px;
    left: -30%;
    bottom: -250px;
  }
}

.nextPageButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  @include mt_vw(32);

  @include responsive(tab) {
    @include mt_vw(16);
  }

  cursor: pointer;

  img {
    height: 22px;
    width: 22px;
    margin-top: 6px;
    transform: scale(1) translateX(0px);
    transition: transform 0.5s;
  }

  &:hover img {
    transform: scale(1) translateX(4px);
    box-shadow: 0 0 8px white;
    border-radius: 32px;
  }

  .linearWipe {
    text-align: center;
    width: auto !important;
    text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.7);
    line-height: 1.5;
    background: linear-gradient(to right,
        #d1bcde 20%,
        #a8dae7 40%,
        #e2e3b9 60%,
        #92e8b4 80%);
    background-size: 200% auto;
    color: #000;
    font-size: 24px;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin: 0;
    margin-left: 8px;
    font-family: CircularStd;
    display: inline-block;

    animation: shine 2s linear infinite;

    @keyframes shine {
      to {
        background-position: 200% center;
      }
    }
  }
}

.header__bg {
  width: 100vw;
  height: 100vh;
  left: 0;
  position: fixed;
  animation: 2s scaleUpBg forwards;
  transform: scale(1);
  pointer-events: none;
}

.rotateAndBounceIn {
  animation: rotateAndBounceIn 1.5s ease-in-out forwards;
}

.rotateAndBounceOut {
  animation: rotateAndBounceOut 2s forwards;
}

.slideLeftFadeOut {
  animation: slideLeftFadeOut 0.5s forwards;
  /* 2秒間のアニメーション */
}