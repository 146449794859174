@use "../mixin/responsive" as *;

@keyframes scaleUp {
  0% {
    transform: rotate(45deg) scale(0.2) translateX(0); /* 初期状態は縮小して、位置は変わらず */
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: rotate(45deg) scale(1.2) translateX(300px) translateY(-300px);
    opacity: 1;
  }
}

@keyframes scaleUpTab {
  0% {
    transform: rotate(45deg) scale(0.2) translateX(0); /* 初期状態は縮小して、位置は変わらず */
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: rotate(45deg) scale(1.2) translateX(200px) translateY(200px);
    opacity: 1;
  }
}

@keyframes scaleUpSp {
  0% {
    transform: rotate(45deg) scale(0.2) translateX(0); /* 初期状態は縮小して、位置は変わらず */
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: rotate(45deg) scale(1.2) translateX(120px) translateY(120px);
    opacity: 1;
  }
}
