@use "../../sass/global/" as *;
@use "../../sass/base/" as *;

.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  left: -200%;
  transform: rotate(
    -45deg
  ); /* Rotates the container 45 degrees counterclockwise */
  transform-origin: center; /* Ensures the rotation is around the center of the element */
  @include responsive(tab) {
    top: -30%;
  }

  .bar1 {
    height: 150px;
    width: 200vw;
    border-radius: 150px;
    animation: slideLeft 2s ease-in-out forwards;
    transform: translateX(180%);
    margin-bottom: -2px;
    @include responsive(sp) {
      animation: slideLeftSp 2s ease-in-out forwards;
      transform: translateX(80%);
      height: 100px !important;
      width: 3500px !important;
    }
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(180%);
  }
  to {
    transform: translateX(-150%);
  }
}

@keyframes slideLeftSp {
  from {
    transform: translateX(80%);
  }
  to {
    transform: translateX(-150%);
  }
}
