@keyframes scaleUpBg {
  0% {
    transform: scale(1); /* 初期状態は縮小して、位置は変わらず */
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5); /* 1倍の大きさに拡大し、右に100px移動 */
    opacity: 1;
  }
}
