/* ----------------------------------------------
 * Generated by Animista on 2023-12-31 4:27:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounceInRight
 * ----------------------------------------
 */

@keyframes bounceInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  30% {
    transform: translateX(0px) scaleX(0.9);
  }
  38% {
    transform: scaleX(1.05);
    opacity: 1;
  }
  55% {
    transform: scaleX(0.95);
  }
  72% {
    transform: scaleX(1.02);
  }
  80% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}
